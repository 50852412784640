<template>
	<div class="requests">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back pencil />
		</div><!-- header -->
		<div class="header-image green"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="SendIcon"
				title="Requests"
				colour="green"
			/>
			<div class="section-gap section-gap-extra">
				<div class="section-title-wrap">
					<div class="icon-split">
						<h2 class="section-title">Open Requests</h2>
						<span>{{ count }}</span>
					</div>
				</div>
				<router-link :to="{ name: 'RequestsOpen' }" class="request-card-wrap">
					<RequestCard
                        v-for="(request, index) in requests"
                        :key="index"
						:category="request.category"
						:title="request.category"
						:content="request.messages.length ? request.messages[0].content : request.message"
						:location="request.port"
						:messageCount="request.messages.length"
						:time="getTimeAgo(request.updated_at)"
						unread
					/>
				</router-link>
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="RequestsComplete"
					class="card-block-cyan-filled"
					icon="TickIcon"
					title="Completed Requests"
					text="View previous requests that have been fulfilled"
				/>
				<Card
					routeName="RequestsNew"
					class="card-block-green-filled"
					icon="PencilIcon"
					title="Make a new Request"
					text="Get help from our in-port team"
				/>
			</div><!-- card wrap -->
			<div class="section-gap section-request-tags">
				<div class="section-title-wrap">
					<h2 class="section-title">Common Requests</h2>
					<router-link :to="{ name: 'RequestsNew' }" class="page-link page-link-grey">View more &raquo;</router-link>
				</div>
				<div class="request-block-wrap" v-dragscroll>
					<RequestBlock
						category="Shopping"
						title="Request Shopping"
                        slug="shopping"
					/>
					<RequestBlock
						category="Visit"
						title="Request a welfare visit"
                        slug="visit"
					/>
					<RequestBlock
						category="Contact"
						title="Help contact a loved one"
                        slug="contact"
					/>
					<RequestBlock
						category="Transport"
						title="Request Transport"
                        slug="transport"
					/>
					<RequestBlock
						category="Justice & Welfare"
						title="Request intervention in welfare issue"
                        slug="justice-welfare"
					/>
					<RequestBlock
						category="Other"
						title="Make a general request"
                        slug="other"
					/>
				</div><!-- port card wrap -->
			</div>
			<div class="section-gap card-wrap">
				<Card
					routeName="RequestsAll"
					class="card-block-dark-grey card-block-full"
					icon="SendIcon"
					category="Requests"
					title="My current requests"
				/>
			</div><!-- card wrap -->
		</div>
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import Card from '@/components/Card.vue';
	import RequestCard from '@/components/RequestCard.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import RequestBlock from '@/components/RequestBlock.vue';
    import UserService from '@/services/UserService';
    import moment from 'moment';
	export default {
		name: 'Requests',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			Card,
			SectionTitle,
			RequestBlock,
			RequestCard
		},
		data () {
	        return {
                requests: [],
                count: 0
	        };
	    },
	    methods: {
            getRequests () {
                UserService.getRequests().then((response) => {
                    this.requests = response.data.requests;
                    this.count = response.data.count;
                });
            },
            getTimeAgo (date) {
                return moment(date).fromNow();
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getRequests();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-gap-extra {
		margin-bottom: 55px;
	}
	.request-card-wrap {
		position: relative;
		display: block;
		.request-card {
			z-index: 3;
			box-shadow: none;
			&:not(:first-child) {
				position: absolute;
				top: 0;
				left: 0;
			}
			&:nth-child(2) {
				transform: scale(0.95);
				top: 20px;
				z-index: 2;
			}
			&:nth-child(3) {
				transform: scale(0.9);
				top: 40px;
				z-index: 1;
				box-shadow: 5px 15px 15px 5px #f6f6f6;
			}
		}
	}
</style>
